//Add only fonts to this file! Styling for icons (.icon, .icon-folder etc. can be added in seperate icons.scss)

//Fira Sans
//Fira Sans Light

@font-face
{
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 300;
    src: url('#{$font-path}FiraSans/fira-sans-v9-latin-300.eot'); /* IE9 Compat Modes */
    src: local('Fira Sans Light'), local('FiraSans-Light'),
    url('#{$font-path}FiraSans/fira-sans-v9-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$font-path}FiraSans/fira-sans-v9-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$font-path}FiraSans/fira-sans-v9-latin-300.woff') format('woff'), /* Modern Browsers */
    url('#{$font-path}FiraSans/fira-sans-v9-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('#{$font-path}FiraSans/fira-sans-v9-latin-300.svg#FiraSans') format('svg'); /* Legacy iOS */
}

//Fira Sans Light Italic

@font-face
{
    font-family: 'Fira Sans';
    font-style: italic;
    font-weight: 300;
    src: url('#{$font-path}FiraSans/fira-sans-v9-latin-300italic.eot'); /* IE9 Compat Modes */
    src: local('Fira Sans Light Italic'), local('FiraSans-LightItalic'),
    url('#{$font-path}FiraSans/fira-sans-v9-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$font-path}FiraSans/fira-sans-v9-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$font-path}FiraSans/fira-sans-v9-latin-300italic.woff') format('woff'), /* Modern Browsers */
    url('#{$font-path}FiraSans/fira-sans-v9-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('#{$font-path}FiraSans/fira-sans-v9-latin-300italic.svg#FiraSans') format('svg'); /* Legacy iOS */
}

//Fira Sans Regular

@font-face
{
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 400;
    src: url('#{$font-path}FiraSans/fira-sans-v9-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Fira Sans Regular'), local('FiraSans-Regular'),
    url('#{$font-path}FiraSans/fira-sans-v9-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$font-path}FiraSans/fira-sans-v9-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$font-path}FiraSans/fira-sans-v9-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('#{$font-path}FiraSans/fira-sans-v9-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('#{$font-path}FiraSans/fira-sans-v9-latin-regular.svg#FiraSans') format('svg'); /* Legacy iOS */
}

//Fira Sans Regular Italic

@font-face
{
    font-family: 'Fira Sans';
    font-style: italic;
    font-weight: 400;
    src: url('#{$font-path}FiraSans/fira-sans-v9-latin-italic.eot'); /* IE9 Compat Modes */
    src: local('Fira Sans Italic'), local('FiraSans-Italic'),
    url('#{$font-path}FiraSans/fira-sans-v9-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$font-path}FiraSans/fira-sans-v9-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$font-path}FiraSans/fira-sans-v9-latin-italic.woff') format('woff'), /* Modern Browsers */
    url('#{$font-path}FiraSans/fira-sans-v9-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('#{$font-path}FiraSans/fira-sans-v9-latin-italic.svg#FiraSans') format('svg'); /* Legacy iOS */
}

//Fira Sans Medium

@font-face
{
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 500;
    src: url('#{$font-path}FiraSans/fira-sans-v9-latin-500.eot'); /* IE9 Compat Modes */
    src: local('Fira Sans Medium'), local('FiraSans-Medium'),
    url('#{$font-path}FiraSans/fira-sans-v9-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$font-path}FiraSans/fira-sans-v9-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$font-path}FiraSans/fira-sans-v9-latin-500.woff') format('woff'), /* Modern Browsers */
    url('#{$font-path}FiraSans/fira-sans-v9-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('#{$font-path}FiraSans/fira-sans-v9-latin-500.svg#FiraSans') format('svg'); /* Legacy iOS */
}

//Fira Sans Medium Italic

@font-face
{
    font-family: 'Fira Sans';
    font-style: italic;
    font-weight: 500;
    src: url('#{$font-path}FiraSans/fira-sans-v9-latin-500italic.eot'); /* IE9 Compat Modes */
    src: local('Fira Sans Medium Italic'), local('FiraSans-MediumItalic'),
    url('#{$font-path}FiraSans/fira-sans-v9-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$font-path}FiraSans/fira-sans-v9-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$font-path}FiraSans/fira-sans-v9-latin-500italic.woff') format('woff'), /* Modern Browsers */
    url('#{$font-path}FiraSans/fira-sans-v9-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('#{$font-path}FiraSans/fira-sans-v9-latin-500italic.svg#FiraSans') format('svg'); /* Legacy iOS */
}

//Fira Sans Bold

@font-face
{
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 700;
    src: url('#{$font-path}FiraSans/fira-sans-v9-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Fira Sans Bold'), local('FiraSans-Bold'),
    url('#{$font-path}FiraSans/fira-sans-v9-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$font-path}FiraSans/fira-sans-v9-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$font-path}FiraSans/fira-sans-v9-latin-700.woff') format('woff'), /* Modern Browsers */
    url('#{$font-path}FiraSans/fira-sans-v9-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('#{$font-path}FiraSans/fira-sans-v9-latin-700.svg#FiraSans') format('svg'); /* Legacy iOS */
}

//Fira Sans Bold Italic

@font-face
{
    font-family: 'Fira Sans';
    font-style: italic;
    font-weight: 700;
    src: url('#{$font-path}FiraSans/fira-sans-v9-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local('Fira Sans Bold Italic'), local('FiraSans-BoldItalic'),
    url('#{$font-path}FiraSans/fira-sans-v9-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$font-path}FiraSans/fira-sans-v9-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$font-path}FiraSans/fira-sans-v9-latin-700italic.woff') format('woff'), /* Modern Browsers */
    url('#{$font-path}FiraSans/fira-sans-v9-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('#{$font-path}FiraSans/fira-sans-v9-latin-700italic.svg#FiraSans') format('svg'); /* Legacy iOS */
}

//Bariol Bold

@font-face
{
    font-family: "Bariol Bold";
    src: url("#{$font-path}BariolBold/Bariol_Bold.otf") format("opentype");
    font-weight: 700;
    font-style: normal;
}

@font-face
{
    // Italic
    font-family: "Bariol Bold";
    src: url("#{$font-path}BariolBold/Bariol_Bold_Italic.otf") format("opentype");
    font-weight: 700;
    font-style: italic;
}

//Oswald Light
@font-face
{

    font-family: "Oswald";
    src: url("#{$font-path}Oswald/Oswald-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

//Oswald Regular
@font-face
{
    font-family: "Oswald";
    src: url("#{$font-path}Oswald/Oswald-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

//Oswald Bold
@font-face
{
    //Bold
    font-family: "Oswald";
    src: url("#{$font-path}Oswald/Oswald-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

//Simple Line Icons
@font-face
{
    font-family: "simple-line-icons";
    src: url("#{$font-path}SimpleLineIcons/Simple-Line-Icons.eot?v=2.4.0");
    src: url("#{$font-path}SimpleLineIcons/Simple-Line-Icons.eot?v=2.4.0#iefix") format("embedded-opentype"),
    url("#{$font-path}SimpleLineIcons/Simple-Line-Icons.woff2?v=2.4.0") format("woff2"),
    url("#{$font-path}SimpleLineIcons/Simple-Line-Icons.ttf?v=2.4.0") format("truetype"),
    url("#{$font-path}SimpleLineIcons/Simple-Line-Icons.woff?v=2.4.0") format("woff"),
    url("#{$font-path}SimpleLineIcons/Simple-Line-Icons.svg?v=2.4.0#simple-line-icons") format("svg");
    font-weight: normal;
    font-style: normal;
}

//Material Design Icons
@font-face
{
    font-family: "Material Design Icons";
    src: url("#{$font-path}MaterialDesignIcons/materialdesignicons-webfont.eot?v=2.4.0");
    src: url("#{$font-path}MaterialDesignIcons/materialdesignicons-webfont.eot?v=2.4.0#iefix")
    format("embedded-opentype"),
    url("#{$font-path}MaterialDesignIcons/materialdesignicons-webfont.woff2?v=2.4.0") format("woff2"),
    url("#{$font-path}MaterialDesignIcons/materialdesignicons-webfont.ttf?v=2.4.0") format("truetype"),
    url("#{$font-path}MaterialDesignIcons/materialdesignicons-webfont.woff?v=2.4.0") format("woff"),
    url("#{$font-path}MaterialDesignIcons/materialdesignicons-webfont.svg?v=2.4.0#materialdesignicons-webfont")
    format("svg");
    font-weight: normal;
    font-style: normal;
}

//Icomoon
@font-face
{
    font-family: "icomoon";
    src: url("#{$font-path}Icomoon/icomoon.ttf?wixr1x") format("truetype"),
    url("#{$font-path}Icomoon/icomoon.woff?wixr1x") format("woff"),
    url("#{$font-path}Icomoon/icomoon.svg?wixr1x#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
}


//Brown

//Brown Light
@font-face
{

    font-family: "Brown";
    src: url("#{$font-path}Brown/Brown-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

//Brown Regular
@font-face
{
    font-family: "Brown";
    src: url("#{$font-path}Brown/Brown-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

//Brown Bold
@font-face
{
    //Bold
    font-family: "Brown";
    src: url("#{$font-path}Brown/Brown-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

// Poppins

//Poppins Light
@font-face {
    font-family: "Poppins";
    src: url("#{$font-path}Poppins/Poppins-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

//Poppins Regular
@font-face {
    font-family: "Poppins";
    src: url("#{$font-path}Poppins/Poppins-Regular.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

//Poppins Bold
@font-face
{
    font-family: "Poppins";
    src: url("#{$font-path}Poppins/Poppins-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}