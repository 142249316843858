
$form-color: $color-text;
$form-color--light: $color-default;
$form-cb-dim: 2.4rem;

.form {
    position: relative;
    color: $form-color;
    background-color: transparent;
    text-align: center;

    h1,
    h2,
    h3 {
        color: var(--color-theme);
    }

    .form__top_label {
        position: absolute;
        top: 0;
        left: $indent-l;
        display: block;
        margin: 0;
        font-size: $font-size-s;

        @include mi-opacity(0.7);
    }

    .form__clarification {
        margin-left: $indent-l;
        margin-top: $indent-l;
        

        p {
            margin: 0;
        }

        .icons__container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            gap: $indent-xs;
            padding: $indent-xxs $indent-xs ;

            .form__clarification__image {
                width: 75px;
                height: auto;
                max-height: 50px;
                object-fit: contain;
            }
        }
    }

    .form__fieldset {
        margin: 0 0 $indent-l;
        padding: 0;
        border-width: 0;
        border: none;
        background: transparent;
        text-align: left;
    }

    .form__fieldset--single {
        @extend .form__fieldset;

        margin-bottom: 0;
    }

    .form__item {
        position: relative;
        margin: 0 0 $indent-xs;
        padding: $indent-l 0 0 $indent-l;

        &.required > label > span:after {
            content: " *";
            position: relative;
        }

        .form__icon {
            position: absolute;
            left: 0;
            top: 3.8rem;
            font-size: $font-size-default;

            &.icon-close {
                top: 3.2rem;
                transition: color 0.2s linear;

                &:hover {
                    color: $color-alert;
                    cursor: pointer;
                    transition: color 0.2s linear;
                }
            }
        }

        &.full-width {
            padding: $indent-l 0;
        }

        .form__checkbox {
            .form__checkbox__input {
                &:focus {
                    + .form__checkbox__label {
                        text-decoration: underline;
                    }
                }
            }
        }

        .form__password_icon {
            position: absolute;
            top: $indent-l;
            right: 0;
            font-size: $font-size-icon;
            z-index: 2;
        }
    }

    .form__items {
        &.privacy_modal {
            margin: 0;

            .form__checkbox {
                padding-bottom: $indent-l;
                width: 100%;
                border-bottom: 2px solid $color-grey-10;

                p {
                    margin-left: 36px;
                    font-size: $font-size-s;
                }
            }
        }
    }

    .form__item--datepicker {
        @extend .form__item;

        .form__label {
            @include mi-opacity(0.7);
        }

        .CalendarDay__selected,
        .CalendarDay__selected:active,
        .CalendarDay__selected:focus {
            border-color: var(--color-theme-light) !important;
            background-color: var(--color-theme-light) !important;
        }

        .SingleDatePicker_picker {
            z-index: 99999 !important;
        }

        .select--alt-black {
            width: 6rem;
            font-size: 1.3rem;
        }
    }

    .form__item--checkbox,
    .form__item--radio {
        @extend .form__item;

        margin: $indent-m 0 0;
        padding-top: 0;
    }

    .form__label {
        position: absolute;
        left: $indent-l;
        top: 0;
        width: calc(100% - #{$indent-l});
        height: 2 * $font-size-s;
        line-height: 1;
        font-size: $font-size-s;
        overflow: hidden;

        @include mi-flexbox();
        @include mi-align-items(flex-end);
        @include mi-opacity(0.7);
        @include mi-transition();

        > span {
            display: block;
        }

        .tooltip-modal {
            position: absolute;
            top: 0;
            right: 0;
            font-size: $font-size-icon-s;
            color: $color-theme-dark;

            @include mi-transition();

            &:hover,
            &:active {
                cursor: pointer;
            }

            + span {
                padding-right: 2.5rem;
            }
        }
    }

    .form__input {
        position: relative;
        width: 100%;
        height: $form-input-height;
        min-height: $form-input-height;
        padding: 0;
        border: none;
        border-bottom: 1px solid $form-color;
        border-radius: 0;
        background: transparent;
        font-family: var(--font-default);;
        font-size: $font-size-default;
        font-weight: $font-weight-medium;
        font-style: italic;
        color: $form-color;
        z-index: 1;
        text-align: left;
        text-overflow: ellipsis;

        @include mi-no-appearance;
        @include mi-transition();

        &.error {
            padding-right: $indent-l;
            background: url("#{$images-path}icon-error.png") no-repeat right center;
            background-size: 1.8rem auto;
        }

        &[disabled],
        &:disabled {
            cursor: not-allowed;

            @include mi-opacity(0.4);
        }
    }

    .form__input--text {
        @extend .form__input;

        + .form__label {
            top: 2.7rem;
            font-size: $font-size-default;
            overflow: visible;
        }

        &:focus,
        &.has-value {
            + .form__label {
                top: 0;
                font-size: $font-size-s;
                overflow: hidden;

                @include mi-opacity(0.7);
            }
        }
    }

    .form__alert {
        display: block;
        padding: $indent-s 0 0;
        color: $color-alert;
        text-align: left;
    }

    .form__input--textarea--dynamic {
        @extend .form__input--text;

        padding-top: 0.5rem;
        resize: none;
        overflow: hidden;

        @include mi-transition();

        &:focus {
            resize: vertical;
            overflow: auto;
            height: 15rem;
        }

        &.has-value {
            height: 15rem;
        }
    }

    .form__input--date {
        @extend .form__input;

        + .form__label {
            @include mi-opacity(0.7);
        }
    }

    textarea.form__input--text {
        height: 15rem;
        resize: vertical;
    }

    .form__range {
        > .form__label {
            @include mi-opacity(0.7);
        }
    }

    .form__select {
        position: relative;

        .form__input--select {
            @extend .form__input;

            white-space: normal;
            height: auto;
            line-height: 1;
            padding: $indent-xxs $indent-m $indent-xxs 0;

            &:focus {
                border-top: 1px solid $color-border;
            }

            option {
                color: $color-text;
            }
        }

        + .form__label {
            @include mi-opacity(0.7);
        }

        &:after {
            content: "";
            position: absolute;
            right: 0;
            top: 1.5rem;
            width: 0;
            height: 0;
            border-left: 0.6rem solid transparent;
            border-right: 0.6rem solid transparent;
            border-top: 0.6rem solid $form-color;
        }
    }

    .form__select[data-after="white"] {
        position: relative;

        .form__input--select {
            @extend .form__input;
        }

        + .form__label {
            @include mi-opacity(0.7);
        }

        &:after {
            content: "";
            position: absolute;
            right: 0;
            top: 1.5rem;
            width: 0;
            height: 0;
            border-left: 0.6rem solid transparent;
            border-right: 0.6rem solid transparent;
            border-top: 0.6rem solid white;
        }
    }



    &.roadmap__form {
        padding-bottom: $indent-hor;
    }

    .form__button_container {
        padding: 0 $indent-hor;
    }

    .form__gc {
        display: block;
    }

    .form__image {
        height: auto;
        margin: 0 0 $indent-l;
    }

    .react-datepicker__input-container {
        display: block;
    }

    .form__profile_img {
        margin: 0 $indent-l $indent-l;

        .form__profile_img__wrapper {
            width: 22rem;
            height: 22rem;
            margin: 0 auto;
            border-radius: 50%;
            overflow: hidden;
            background: $color-grey-5;
        }

        img {
            position: absolute;
            width: auto;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            max-height: 100%;
            max-width: 100%;
            min-width: 100%;

            @include mi-image-crisp();
        }
    }

    //Overwrite color of chrome's suggestion text
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-text-fill-color: $form-color !important;
    }

    @media (max-width: $screen-xs) {
        padding-top: 1px;
        padding-left: $indent-base;
        padding-right: $indent-base;
        border-radius: 0;

        .form__button_container {
            padding: 0 $indent-l;
        }

        .form__profile_img {
            margin: 0 $indent-hor $indent-l;
        }
    }

    @media (max-width: $screen-xxs) {
        .form__profile_img {
            margin: 0 0 $indent-m;
        }

        .form__top_label {
            left: 0;
        }

        .form__clarification {
            margin-left: 0;
        }
    }

    .form__forgot-password {
        padding-top: $indent-m;
        margin-top: $indent-xl;
        border-top: 2px solid $color-default-40;
        color: $color-purple;

        p,
        a {
            display: inline-block;
            width: auto;
            padding: 0;
        }

        a {
            text-decoration: underline;
        }
    }
}

.form__buttons {
    margin: $indent-m 0 0;
    text-align: right;

    .button,
    .button--cta {
        display: inline-block;
        width: auto;
        vertical-align: top;
    }
}

.form__checkbox,
.form__radio {
    position: relative;
    display: inline-block;
    margin-bottom: 0.6rem;
    margin-top: 0.5rem;
    text-align: left;

    &.block {
        display: block;
    }

    .form__checkbox__input,
    .form__radio__input {
        position: absolute;

        @include mi-opacity(0);

        + .form__checkbox__label,
        + .form__radio__label {
            display: block;
            padding-left: 3.5rem;
            position: relative;
            cursor: pointer;
            text-align: left;

            /* Disable selection on checkbox label, necessary to be able to select and deselect the element very fast */
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            > span {
                display: inline-block;
                padding: 0 0.5rem;
            }

            &:before {
                content: " ";
                position: absolute;
                background-size: 100% auto;
                background-repeat: no-repeat;
                background-position: top center;
                height: $form-cb-dim;
                width: $form-cb-dim;
                margin: 0;
                left: 0;
                top: 0;
            }

            a {
                text-decoration: underline;
                font-weight: $font-weight-medium;

                &:hover,
                &:active {
                    text-decoration: none;
                }
            }
        }

        &:checked {
            + .form__checkbox__label,
            + .form__radio__label {
                &:before {
                    background-position: 0 (-$form-cb-dim);
                }
            }
        }

        &:disabled {
            + .form__checkbox__label,
            &:before {
                background-position: 0 (-$form-cb-dim);
                cursor: default;

                @include mi-opacity(0.15);
            }
        }

        &:disabled,
        &[disabled] {
            + .form__radio__label {
                cursor: not-allowed;

                @include mi-opacity(0.4);
            }
        }
    }

    &.small {
    }
}


.form__radio {
    margin-right: 6rem;

    .form__radio__input {
        + .form__radio__label {
            &:before {
                background-image: url("#{$images-path}radio-sprite-dark.png");
            }
        }
    }
}

.form__checkbox {
    .form__checkbox__input {
        + .form__checkbox__label {
            &:before {
                background-image: url("#{$images-path}checkbox-sprite-dark.png");
            }
        }
    }
}

.form__checkbox--white {
    .form__checkbox__input {
        + .form__checkbox__label {
            &:before {
                background-image: url("#{$images-path}checkbox-sprite-white.png");
            }
        }
    }
}

.form__item--school-subject {
    width: 90%;
    float: right;
}

#schoolSubjectForm {
    margin-top: 64px;

    .form__item {
        padding-top: $indent-s;
        margin: 0 !important;

        .select--alt {
            width: 100% !important;
        }

        .icons {
            font-size: 1.5rem;
            vertical-align: middle;
            position: relative;
            margin-right: 8px;
        }
    }
    .last {
        padding-bottom: $indent-l;
    }

}


.tooltip {
    z-index: 10000 !important;
}

.tooltip-modal {
    margin-left: 0.2em;
}
.error {
    padding-right: $indent-l;
    background: url("#{$images-path}icon-error.png") no-repeat right center;
    background-size: 1.8rem auto;
}

.form__item--address:nth-of-type(1) {
    padding-right: 0 !important;

}

.form__item--address:nth-of-type(2) {
    padding-left: 0 !important;
    padding-right: 0 !important;

    @media (max-width: $screen-xs) {
        padding-left: $indent-s !important;
        padding-right: $indent-s !important;
    }
}

.form__item--address:nth-of-type(3) {
    padding-left: 0 !important;
}
